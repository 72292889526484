
import { Portal } from 'portal-vue'

const HAS_WINDOW_SUPPORT = typeof window !== 'undefined'
const HAS_DOCUMENT_SUPPORT = typeof document !== 'undefined'
const HAS_NAVIGATOR_SUPPORT = typeof navigator !== 'undefined'
const IS_BROWSER = HAS_WINDOW_SUPPORT && HAS_DOCUMENT_SUPPORT && HAS_NAVIGATOR_SUPPORT

const WINDOW = process.client ? window : {}
const requestAF = (
  WINDOW.requestAnimationFrame ||
  WINDOW.webkitRequestAnimationFrame ||
  WINDOW.mozRequestAnimationFrame ||
  WINDOW.msRequestAnimationFrame ||
  WINDOW.oRequestAnimationFrame ||
  ((cb) => setTimeout(cb, 16))
).bind(WINDOW)

const CAPTURE_EVENT_OPTIONS = { passive: true, capture: false }

// controls
const HANDLE_SHOW_EVENT = 'show::zmodal'
const HANDLE_HIDE_EVENT = 'hide::zmodal'

// prepended to root event emits
const rootEventPrefix = 'zmodal::'

// emits
const EMIT_SHOW_EVENT = 'show'
const EMIT_HIDE_EVENT = 'hide'
const EMIT_SHOWN_EVENT = 'shown'
const EMIT_HIDDEN_EVENT = 'hidden'
const EMIT_MODEL_EVENT = 'input'

export default {
  components: {
    Portal
  },

  inheritAttrs: false,
  model: {
    prop: 'visible',
    event: EMIT_MODEL_EVENT
  },

  props: {
    // visible has v-model support
    noPortal: {
      type: Boolean,
      default: false
    },

    visible: {
      type: Boolean,
      default: false
    },

    title: {
      type: String,
      default: null
    },

    subtitle: {
      type: String,
      default: null
    },

    hideHeader: {
      type: Boolean,
      default: false
    },

    hideFooter: {
      type: Boolean,
      default: false
    },

    size: {
      type: String,
      default: 'md',
      validator: (value) => {
        return ['sm', 'md', 'lg', 'xl'].includes(value)
      }
    },

    id: {
      type: String,
      default: null
    },

    centered: {
      type: Boolean,
      default: false
    },

    footerSpaceBetween: {
      type: Boolean,
      default: false
    },

    footerNoPadding: {
      type: Boolean,
      default: false
    },

    scrollable: {
      type: Boolean,
      default: false
    },

    noCloseOnEsc: {
      type: Boolean,
      default: false
    },

    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },

    hideHeaderClose: {
      type: Boolean,
      default: false
    },

    padded: {
      type: Boolean,
      default: false
    },

    modalClass: {
      type: String,
      default: null
    },

    headerBottomBorder: {
      type: Boolean,
      default: false
    },

    disableEnsureFocus: {
      type: Boolean,
      default: false
    }
  },

  emits: [EMIT_SHOW_EVENT, EMIT_SHOWN_EVENT, EMIT_HIDE_EVENT, EMIT_HIDDEN_EVENT, EMIT_MODEL_EVENT],

  data() {
    return {
      ignoreBackdropClick: false,
      isHidden: true,
      isBlock: false,
      isVisible: false,
      isShow: false,
      isOpening: false,
      isClosing: false
    }
  },

  computed: {
    computedAttrs() {
      const scopeId = this.getScopeId(this.$parent?.$parent)
      return scopeId ? { [scopeId]: '' } : {}
    },

    modalClasses() {
      return ['zmodal', 'fade', this.modalClass, { show: this.isShow }]
    },

    modalStyles() {
      return { display: this.isBlock ? 'block' : 'none' }
    },

    contentClasses() {
      return ['zmodal-content']
    },

    dialogClasses() {
      return [
        'zmodal-dialog',
        `zmodal-${this.size}`,
        this.dialogClass,
        { 'zmodal-dialog-scrollable': this.scrollable, 'zmodal-dialog-centered': this.centered }
      ]
    },

    headerClasses() {
      return ['zmodal-header', { 'zmodal-header-padded': this.padded, 'bottom-border': this.headerBottomBorder }]
    },

    bodyClasses() {
      return [
        'zmodal-body',
        {
          'zmodal-body-padded': this.padded,
          'zmodal-body-no-header': this.hideHeader,
          'zmodal-body-no-footer': this.hideFooter
        }
      ]
    },

    footerClasses() {
      return [
        'zmodal-footer',
        {
          'zmodal-footer-padded': this.padded,
          'zmodal-footer-between': this.footerSpaceBetween,
          'zmodal-footer-no-padding': this.footerNoPadding
        }
      ]
    },

    modalId() {
      return this.id
    },

    eventPayload() {
      return {
        vueTarget: this,
        componentId: this.modalId
      }
    }
  },

  watch: {
    // trigger show()/hide() methods based on visibility changes
    visible(newValue, oldValue) {
      if (newValue !== oldValue) {
        this[newValue ? 'show' : 'hide']()
      }
    }
  },

  /* lifecycle methods */

  mounted() {
    // listen on root to handle show and hide events
    this.$nuxt.$on(HANDLE_SHOW_EVENT, this.showHandler)
    this.$nuxt.$on(HANDLE_HIDE_EVENT, this.hideHandler)

    // listen on root for other modals being shown so we can hide ourself
    this.$nuxt.$on(EMIT_SHOW_EVENT, this.moddalListener)

    if (this.visible) {
      this.$nextTick(this.show)
    }
  },

  beforeDestroy() {
    this.unregisterModal()
    if (this.isVisible) {
      this.isVisible = false
      this.isShow = false
    }
  },

  methods: {
    /* actions */
    show() {
      if (this.isVisible || this.isOpening) {
        return
      }

      this.emitEvent(EMIT_SHOW_EVENT)
      this.registerModal()
      this.isOpening = true
      this.isHidden = false
      this.$nextTick(() => {
        // nextTick to ensure modal is in DOM before showing
        this.isVisible = true
        this.isOpening = false
        this.updateModel(true)
      })
    },

    hide(trigger) {
      if (!this.isVisible || this.isClosing) {
        return
      }

      this.emitEvent(EMIT_HIDE_EVENT, trigger)
      this.isclosing = true
      this.isVisible = false
      this.updateModel(false)
    },

    showHandler(id) {
      if (id === this.modalId) {
        this.show()
      }
    },

    hideHandler(id) {
      if (id === this.modalId) {
        this.hide()
      }
    },

    modalListener(zmodalEvent) {
      // if another modal opens, close this one
      if (zmodalEvent.vueTarget !== this) {
        this.hide()
      }
    },

    focusHandler(event) {
      // If focus leaves modal content, bring it back
      const content = this.$refs.content
      const { target } = event
      if (
        !this.isVisible ||
        !content ||
        document === target ||
        content.contains(target) ||
        (target && this.contentClasses.every((cls) => target.className.includes(cls))) // Sometimes the top of the modal is not the same as the content from ref. Break recursion by ending here
      ) {
        return
      }

      const tabables = this.getTabables(this.$refs.content)
      const bottomTrap = this.$refs['bottom-trap']
      const topTrap = this.$refs['top-trap']
      if (bottomTrap && target === bottomTrap) {
        // If user pressed TAB out of modal into our bottom trab trap element
        // Find the first tabable element in the modal content and focus it
        if (this.attemptFocus(tabables[0])) {
          // Focus was successful
          return
        }
      } else if (topTrap && target === topTrap) {
        // If user pressed CTRL-TAB out of modal and into our top tab trap element
        // Find the last tabable element in the modal content and focus it
        if (this.attemptFocus(tabables[tabables.length - 1])) {
          // Focus was successful
          return
        }
      }
      // Otherwise focus the modal content container
      this.attemptFocus(content, { preventScroll: true })
    },

    /* action  handlers */

    handleDialogMousedown() {
      // Watch to see if the matching mouseup event occurs outside the dialog
      // And if it does, cancel the clickOut handler

      const modal = this.$refs.modal
      const onceModalMouseup = (event) => {
        if (modal && modal.removeEventListener) {
          modal.removeEventListener('mouseup', onceModalMouseup, CAPTURE_EVENT_OPTIONS)
        }

        if (event.target === modal) {
          this.ignoreBackdropClick = true
        }
      }

      if (modal && modal.addEventListener) {
        modal.addEventListener('mouseup', onceModalMouseup, CAPTURE_EVENT_OPTIONS)
      }
    },

    handleClickOut(event) {
      if (this.ignoreBackdropClick) {
        // Click was initiated inside the modal content, but finished outside.
        // Set by the above handleDialogMouseDown handler
        this.ignoreBackdropClick = false
        return
      }
      // Do nothing if not visible, backdrop click disabled, or element
      // that generated click event is no longer in document body
      if (!this.isVisible || this.noCloseOnBackdrop || !document.body.contains(event.target)) {
        return
      }
      // If backdrop clicked (content not clicked), hide modal
      if (!this.$refs.content.contains(event.target)) {
        this.hide('backdrop')
      }
    },

    handleKeyDown(event) {
      const KEYCODE_ESC = 27
      if (event.keyCode === KEYCODE_ESC && !this.noCloseOnEsc) {
        this.hide('esc')
      }
    },

    /* animation lifecycle */

    handleEnter() {
      this.isBlock = true
      // We add the `show` class 1 frame later
      // `requestAF()` runs the callback before the next repaint, so we need
      // two calls to guarantee the next frame has been rendered
      requestAF(() => {
        requestAF(() => {
          this.isShow = true
        })
      })
    },

    handleAfterEnter() {
      requestAF(() => {
        this.emitEvent(EMIT_SHOWN_EVENT)
        if (!this.disableEnsureFocus) {
          this.setEnforceFocus(true)
        }
        this.$nextTick(() => {
          // Delayed in a `$nextTick()` to allow users time to pre-focus
          this.focusFirst()
        })
      })
    },

    handleBeforeLeave() {
      if (!this.disableEnsureFocus) {
        this.setEnforceFocus(false)
      }
    },

    handleLeave() {
      this.isShow = false
    },

    handleAfterLeave() {
      this.isBlock = false
      this.isHidden = true
      this.$nextTick(() => {
        this.isClosing = false
        this.unregisterModal()
        this.emitEvent(EMIT_HIDDEN_EVENT)
      })
    },

    /* private utility functions */

    focusFirst() {
      // Don't try and focus if we are SSR
      if (!IS_BROWSER) return

      requestAF(() => {
        const modal = this.$refs.modal
        const content = this.$refs.content
        const activeElement = document.activeElement
        // If the modal contains the activeElement, we don't do anything
        if (modal && content && !(activeElement && content.contains(activeElement))) {
          // Focus the element
          this.attemptFocus(content)
          // Make sure top of modal is showing (if longer than the viewport)
          this.$nextTick(() => {
            modal.scrollTop = 0
          })
        }
      })
    },

    updateModel(value) {
      if (this.visible !== value) {
        this.$emit('input', value)
      }
    },

    getTabables(inputElement) {
      const tabbableElements = []

      function isTabbable(element) {
        return element.tabIndex >= 0
      }

      function traverseElements(element) {
        if (isTabbable(element)) {
          tabbableElements.push(element)
        }

        const children = element.children
        for (let i = 0; i < children.length; i++) {
          traverseElements(children[i])
        }
      }

      traverseElements(inputElement)
      return tabbableElements
    },

    attemptFocus(el, options = {}) {
      try {
        el.focus(options)
      } catch (e) {
        // ignore
      }
      return document.activeElement === el
    },

    getScopeId(vm) {
      return vm ? vm.$options._scopeId : null
    },

    setEnforceFocus(on) {
      if (on) {
        document.addEventListener('focusin', this.focusHandler, CAPTURE_EVENT_OPTIONS)
      } else {
        document.removeEventListener('focusin', this.focusHandler, CAPTURE_EVENT_OPTIONS)
      }
    },

    emitEvent(event, trigger) {
      // emit a custom event on $root and this instance
      this.$nuxt.$emit(`${rootEventPrefix}${event}`, this.eventPayload)
      this.$emit(event, { ...this.eventPayload, trigger })
    },

    registerModal() {
      document.body.classList.add('zmodal-open')
    },

    unregisterModal() {
      document.body.classList.remove('zmodal-open')
    }
  }
}
