import { render, staticRenderFns } from "./auth.vue?vue&type=template&id=878b985c"
import script from "./auth.vue?vue&type=script&lang=js"
export * from "./auth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "~/locales/common/auth.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fvsts%2Fwork%2F1%2Fs%2Fcomponents%2Fapp%2Fauth.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormAuth: require('/home/vsts/work/1/s/components/form/auth.vue').default,ZModal: require('/home/vsts/work/1/s/components/style-guide/modal.vue').default})
