
import authMixin from '~/mixins/auth'
import { trackSignupViewed } from '~/lib/tracking/index.js'
import { AuthProviders } from '~/lib/auth/index.js'

export default {
  mixins: [authMixin],

  data() {
    return {
      skipToForm: null,
      showModal: false,
      isLogin: false,
      showEmailForm: null,
      intent: '',
      ownerName: ''
    }
  },

  computed: {
    title() {
      switch (this.intent) {
        case 'ListingIntent':
          return this.$t('auth.listYourRV')

        case 'BookingIntent':
          return this.$t('auth.bookingIntentTitle')

        case 'EnquiryIntent':
          return this.$t('auth.enquiryIntentTitle')

        default:
          if (this.isLogin) {
            return this.$t('auth.loginToRVezy')
          }
          return this.$t('auth.joinRVezy')
      }
    },

    isOwner() {
      return this.getRouteBaseName() === 'owner'
    }
  },

  watch: {
    '$route.path': {
      handler() {
        if (this.$isServer) {
          return
        }
        if (this.getRouteBaseName() === 'index-signin') {
          this.openModal({ isLogin: true })
        } else if (this.getRouteBaseName() === 'index-signup') {
          this.openModal({})
        } else if (this.showModal) {
          this.hideModal('navigation')
        }
      },

      immediate: true
    }
  },

  beforeDestroy() {
    if (this.showModal === true && !this.$auth.loggedIn) {
      this.trackSignupExitedEvent({
        trigger: 'navigation',
        isLogin: this.isLogin,
        isOwner: this.isOwner,
        provider: this.showEmailForm ? 'email' : null
      })
    } else {
      this.$nuxt.$off('signup')
      this.$nuxt.$off('login')
    }
  },

  mounted() {
    this.$nuxt.$on('signup', (params = {}) => {
      this.$authUtils.initAuthProcess({
        disableRedirectAfterSuccessAndRun: params.disableRedirectAfterSuccessAndRun,
        redirectSuggestedAfterAuthSuccess: params.redirectSuggestedAfterAuthSuccess
      })
      this.skipToForm = params.skipToForm
      this.openModal({
        intent: params.intent,
        ownerName: params.ownerName
      })
    })
    this.$nuxt.$on('login', (params = {}) => {
      this.$authUtils.initAuthProcess({
        disableRedirectAfterSuccessAndRun: params.disableRedirectAfterSuccessAndRun,
        redirectSuggestedAfterAuthSuccess: params.redirectSuggestedAfterAuthSuccess
      })

      this.skipToForm = params.skipToForm
      this.openModal({ isLogin: true })
    })
  },

  methods: {
    openModal({ isLogin = false, intent = '', ownerName = '' }) {
      this.isLogin = isLogin
      this.intent = intent
      this.ownerName = ownerName
      if (isLogin === false) {
        trackSignupViewed({
          signupTypes: AuthProviders,
          isOwner: this.isOwner,
          cta: this.getRouteBaseName(),
          pageSource: this.getRouteBaseName()
        })
      }
      this.showModal = true
      this.showEmailForm = null
    },

    closeModal() {
      this.showModal = false
      this.showEmailForm = null
    },

    updateEmailForm(toVal) {
      this.showEmailForm = toVal
    },

    hideModal(trigger) {
      this.trackSignupExitedEvent({
        trigger: ['headerclose', 'esc'].includes(trigger) ? 'close' : trigger,
        isLogin: this.isLogin,
        isOwner: this.isOwner,
        provider: this.showEmailForm ? 'email' : null
      })

      if (trigger === 'headerclose' && ['index-signin', 'index-signup'].includes(this.getRouteBaseName())) {
        this.$router.replace(this.localePath('index'))
      }

      this.closeModal()
    },

    forgotPassword() {
      this.isLogin = true
      if (this.getRouteBaseName()?.includes('recovery-password')) {
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$nuxt.$emit('hide::zmodal', 'zmodal-auth')
      } else {
        this.$router.push({
          path: this.localePath('recovery-password'),
          query: this.recoveryPasswordQuery
        })
      }
    },

    updateIsLogin(toVal) {
      this.isLogin = toVal
    }
  }
}
