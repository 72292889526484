
import authMixin from '~/mixins/auth'
import { AuthProviders } from '@/lib/auth/index.js'
import { AuthenticationType } from '~/lib/enums'
import { trackSignupViewed } from '~/lib/tracking'

export default {
  name: 'AuthForm',

  mixins: [authMixin],

  props: {
    isLogin: {
      type: Boolean,
      default: false
    },

    intent: {
      type: String,
      default: null
    },

    ownerSignup: {
      type: Boolean,
      default: false
    },

    skipToForm: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      showEmailForm: this.skipToForm || null
    }
  },

  computed: {
    AuthenticationType() {
      return AuthenticationType.$all()
    }
  },

  watch: {
    showEmailForm(toVal) {
      this.$emit('update-email-form', toVal)
    }
  },

  methods: {
    toggleAuth() {
      if (this.isLogin) {
        this.showSignup()
      } else {
        this.changeFormToLogin()
      }
    },

    showSignup() {
      this.showEmailForm = null
      trackSignupViewed({
        signupTypes: AuthProviders,
        isOwner: this.ownerSignup,
        cta: this.getRouteBaseName(),
        pageSource: this.getRouteBaseName()
      })

      this.$emit('update-is-login', false)
      this.$emit('update-email-form', false)
    },

    forgotPassword() {
      this.$emit('forgot-password')
    },

    changeFormToLogin() {
      this.trackSignupExitedEvent({
        trigger: 'login',
        isLogin: false,
        isOwner: this.ownerSignup,
        provider: this.showEmailForm === null ? null : 'email'
      })
      this.$emit('update-is-login', true)
    },

    closeModal() {
      this.$emit('close-modal')
    }
  }
}
